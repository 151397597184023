// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-entry-template-index-jsx": () => import("./../../../src/components/blogEntryTemplate/index.jsx" /* webpackChunkName: "component---src-components-blog-entry-template-index-jsx" */),
  "component---src-components-category-template-index-jsx": () => import("./../../../src/components/categoryTemplate/index.jsx" /* webpackChunkName: "component---src-components-category-template-index-jsx" */),
  "component---src-components-page-template-index-jsx": () => import("./../../../src/components/pageTemplate/index.jsx" /* webpackChunkName: "component---src-components-page-template-index-jsx" */),
  "component---src-components-tag-template-index-jsx": () => import("./../../../src/components/tagTemplate/index.jsx" /* webpackChunkName: "component---src-components-tag-template-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-archivo-jsx": () => import("./../../../src/pages/archivo.jsx" /* webpackChunkName: "component---src-pages-archivo-jsx" */),
  "component---src-pages-contacta-jsx": () => import("./../../../src/pages/contacta.jsx" /* webpackChunkName: "component---src-pages-contacta-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

